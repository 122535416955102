<template>
  <input-container
    class="input-textarea"
    :label="label"
    :assistive="assistive"
    :no-assistive="noAssistive"
    :max="max"
    :count="input ? input.length : 0"
    :char-counter="charCounter"
    :classes="classes"
    :body-styles="bodyStyles"
    :class="{ focused, disabled, invalid }">
    <template #container>
      <slot name="icon-left"></slot>
      <textarea
        class="textarea-body"
        ref="input"
        :style="textareaStyles"
        :placeholder="placeholder"
        :name="name"
        v-model="input"
        @focus="focus(true)"
        @blur="focus(false)"
        :disabled="disabled"
        :delay="delay"
      />
      <button type="button" class="btn fab fab-sm icon close color primary m-r-16" v-if="showCleanButton" @click="clear"></button>
      <span class="font m-r-12 l-h-24 f-s-16 f-w-500" v-if="enhancerLabel">{{ enhancerLabel }}</span>
    </template>
  </input-container>
</template>

<script>
import InputContainer from './input-container.vue';

export default {
  components: {
    InputContainer,
  },
  data() {
    return {
      focused: false,
      input: '',
    };
  },
  props: {
    noAssistive: {
      type: Boolean,
      default: false,
    },
    enhancerLabel: {
      type: [Boolean, String],
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    clean: {
      type: Boolean,
      default: false,
    },
    value: {},
    label: {
      type: [String, Boolean],
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    assistive: {
      type: [String, Boolean],
      default: false,
    },
    error: {
      type: [Boolean, String, Array],
      default: false,
    },
    classes: {
      type: String,
      default: 'p-b-32',
    },
    textareaStyles: {
      type: [String, Object],
      default: '',
    },
    bodyStyles: {
      type: [String, Object],
      default: 'height: 84px !important',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reduce: {
      type: Function,
      default: (v) => v,
    },
    delay: {
      type: Number,
      default: 0,
    },
    max: {
      type: [String, Number],
      default: 120,
    },
    charCounter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showCleanButton() {
      return !this.disabled && this.clean && this.value.length;
    },
    invalid() {
      return Boolean(this.error || this.error.length);
    },
  },
  watch: {
    input(input) {
      this.$emit('input', this.reduce(input));
    },
    value(value) {
      this.input = this.reduce(value);
    },
  },
  methods: {
    focus(focus) {
      this.focused = focus;
    },
    blur(blur) {
      this.focused = blur;
    },
    clear() {
      this.$refs.input.clear();
    },
  },
  mounted() {
    this.input = this.reduce(this.value);
  },
};
</script>

<style lang="scss">
.input-textarea {
    height: auto !important;
    .input-body {
        .textarea-body {
            background-color: transparent;
            border: unset;
            width: 100%;
            resize: none;
            padding: 12px;
            height: inherit !important;
            &:focus {
                outline: unset;
            }
        }
    }
}
</style>
