<template>
    <div class="page-loading" :style="{
        position,
        ...styles
    }">
        <div :class="bodyClass">
            <loading-svg />
            <p v-if="!noText" class="font f-s-14 l-h-20 f-w-400">Hold tight <br
              /> just getting this 
              page ready</p>
        </div>
    </div>
</template>

<script>
import loadingSvg from './loading-svg.vue';

export default {
  props: {
    bodyClass: {
      type: String,
      default: 'page-loading-container',
    },    
    position: {
      type: String,
      default: 'fixed',
    },
    noText: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: Object,
      default: () => ({
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%, -50%)',
      }),
    },
  },
  components: {
    loadingSvg,
  },
};
</script>

<style lang="scss" scoped>
.page-loading {
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 100;
    .page-loading-container {
        width: 177px;
    }

    &.block {
      left: 0; 
      top: 0; 
      bottom: 0; right: 0
    }
}
</style>
