<template>
  <input-container class="textarea-advanced" :label="label" :assistive="assistive" :no-assistive="noAssistive"
    :class="{ focused, disabled, invalid, 'text-style': disabled }">

    <template #container>
      <div class="textarea-advanced-container justify-content-between align-items-center" :class="{ 'has-counter': counter || overloade }">
        <template v-if="disabled">
          <p class="p-x-6 font f-w-500 f-s-16 l-h-20 m-0 text-color w-100 primary textarea-advanced-disabled">{{ text }}</p>
        </template>
        <template v-else>
          <textarea v-model="text" :placeholder="placeholder" name="name" ref="textarea" class="textarea-advanced-wrapper"
            :class="textareaClass" :disabled="disabled" @keypress.enter="keyPressedEnter" @input="setValue">
          </textarea>
        </template>

        <template v-if="!loading">
          <CharCounter ref="charCounter" v-if="isTyping || overloade" class="textarea-advanced-counter p-l-4 p-r-16"
            :max="max" :count="count" @overload="setOverloade" />
        </template>

        <template v-if="loading">
          <div style="max-width: 92px;">
            <LoadingSvg />
          </div>
        </template>
      </div>
    </template>
  </input-container>
</template>

<script>
import CharCounter from '../char-counter.vue';
import InputContainer from './input-container.vue';

export default {
  components: {
    CharCounter,
    InputContainer,
  },
  props: {
    noAssistive: {
      type: Boolean,
      default: false,
    },
    label: {
      type: [String, Boolean],
      default: false,
    },
    assistive: {
      type: [String, Boolean],
      default: false,
    },
    counter: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    max: {
      type: [String, Number],
      default: 100,
    },
    perLine: {
      type: [String, Number],
      default: 70,
    },
    error: {
      type: [Boolean, String, Array],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    textareaClass: {
      type: String,
      default: 'font f-w-500 f-s-16 l-h-20 p-0',
    },
    heightPerLine: {
      type: Number,
      default: 20,
    },
    delay: {
      type: Number,
      default: 300
    }
  },
  computed: {
    hasText() {
      return this.count;
    },
    count() {
      return this.text.length;
    },
    invalid() {
      return this.error.length;
    },
  },
  data() {
    return {
      focused: false,
      text: this.value || '',
      isTyping: false,
      timeout: null,
      overloade: false,
    };
  },
  mounted() {
    this.text = this.value;

    if (!this.disabled) {
      this.autoResize();
    }
  },
  watch: {
    value(value) {
      this.text = value;
    },
    text(str) {
      this.text = str.replace(/\r?\n|\r/g, ' ');
    },
    loading(loading) {
      if (!loading) {
        this.$nextTick(() => {
          this.$refs.textarea.focus();
        })
      }
    }
  },
  filters: {
    sanitizer(str) {
      return str.replace(/\r?\n|\r/g, ' ');
    },
  },
  methods: {
    focus(focus) {
      this.focused = focus;
    },
    blur(blur) {
      console.log("text", this.text.length)
      if (!this.text.length) {
        this.text = '';
      }

      this.focused = blur;
    },
    keyPressedEnter() {
      this.$emit('pressed-enter', this.text.trim());
    },
    endOfContent(focus) {
      document.execCommand('selectAll', false, null);
      document.getSelection().collapseToEnd();

      if (focus) {
        this.$nextTick(() => {
          this.$refs.textarea.focus();
        })
      }
    },
    autoResize() {
      this.$refs.textarea.style.height = 'auto';
      const perLine = this.isTyping ? this.perLine : (this.perLine + 7);
      const h = (Math.ceil(this.text.length / perLine) * this.heightPerLine) || this.heightPerLine;
      this.$refs.textarea.style.height = `${h}px`;
    },
    setValue() {
      this.isTyping = true;
      const text = this.text.trim();
      if (!text.length) {
        this.text = text;
        return;
      }

      this.autoResize();
      if (this.timeout) clearTimeout(this.timeout);


      this.timeout = setTimeout(() => {
        this.isTyping = false;
        this.getOuterText();
      }, this.delay);
    },
    getOuterText() {
      this.$emit('input', this.text);
    },
    setOverloade(bool) {
      this.overloade = bool;
    },
  },
};
</script>

<style lang="scss">
.textarea-advanced {
  min-width: inherit;
  width: 100%;
  padding: 14px 12px !important;
  border: 1px solid #EEE;
  border-radius: 8px;

  &-disabled {
    padding: 14px 12px !important;
    overflow: hidden;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  &.text-style {
    padding: 0 !important;
    border: unset;
  }

  .input-body {
    height: auto !important;
    border: unset !important;
    display: unset !important;
  }

  &-container {
    position: relative;
    min-width: inherit;
    max-width: inherit;
    width: inherit;
    display: flex;

    &.has-counter {
      .textarea-advanced-wrapper {
        min-width: calc(100% - 40px) !important
      }
    }

  }

  &-placeholder {
    position: absolute;
    left: 0;
    // top: 50%;
    // transform: translateY(-50%);
    min-width: inherit;
    width: 100%;
    z-index: -1;
  }

  &-wrapper {
    resize: none;
    display: block;
    color: #141414;
    width: 100%;
    min-width: 100%;
    border: unset;
    outline: unset;
    overflow: hidden;
    transition: all .1s;

    &-is-empty {
      opacity: 0.5;
    }

    &:disabled {
      background-color: transparent;
    }
  }
}
</style>
