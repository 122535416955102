<template>
  <div class="alert" :class="`alert-${variant}`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  padding: 16px;
  box-sizing: border-box;
  border-radius: 12px;
  display: inline-block;
  // margin: 0px;

  &-default {
    background: #F6F6F6;
    border-radius: 8px;
    padding: 12px;
  }

  &-warning {
    color: #BC8B2C;
    background-color: #FFFAF0;
    border: 1px solid #FFE3AC;
  }

  &-danger {
    color: #E11900;
    background: #FFEFED;
    border: 1px solid #F1998E;
  }
}
</style>
