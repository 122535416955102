<template>
    <span :class="`char-counter text-color tertiary font ${defaultClasses}`">
        <span class="text-count" :class="{ 'is-invalid': isInvalid }">{{ count | format }}</span>
        <span>/</span>
        <span class="text-max">{{ max }}</span>
    </span>
</template>

<script>
export default {
  props: {
    max: {
      type: [Number, String],
      default: 100,
    },
    count: {
      type: [Number, String],
      default: 0,
    },
    defaultClasses: {
      type: String,
      default: 'f-s-12 l-h-16 f-w-500',
    },
  },
  filters: {
    format(value) {
      return (value).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    },
  },
  computed: {
    isInvalid() {
      return this.count > this.max;
    },
  },
  watch: {
    isInvalid(bool) {
      this.$emit('overload', bool);
    },
  },
};
</script>

<style lang="scss" scoped>
.char-counter {
    width: 40px;
    display: flex;
    .text-count {
        &.is-invalid {
          color: #E11900;
        }
    }

    .text-max {}
}
</style>
